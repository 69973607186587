const MediaCategory = {
    INSIGHTS: 'insights',
    NEWS: 'news',
    EVENTS: 'events',
};

export const MEDIA_MKT_LABEL = {
    [MediaCategory.INSIGHTS]: MediaCategory.INSIGHTS,
    [MediaCategory.EVENTS]: MediaCategory.EVENTS,
    [MediaCategory.NEWS]: 'media',
};

export default MediaCategory;
