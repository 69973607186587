import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRouter from 'src/shared/hooks/useRouter';
import { globalActions } from 'src/shared/store/global';
import { RootState } from 'src/store/rootReducer';

function useDisclaimer() {
    const dispatch = useDispatch();
    const router = useRouter();
    const confirmDisclaimer = useSelector((state: RootState) => state.preference.confirmDisclaimer);

    useEffect(() => {
        if (!confirmDisclaimer && !router.isMobile) {
            dispatch(globalActions.showDisclaimerModal());
        }
    }, [confirmDisclaimer, dispatch, router.isMobile]);
}

export default useDisclaimer;
