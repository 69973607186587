import React from 'react';
import BaseText from '../BaseText';
import styles from './styles.module.scss';

interface Props {
    className?: string;
    color?: 'dark' | 'white';
    children: React.ReactNode;
    tag?: 'p' | 'label' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const TitleText = (props: Props) => (
    <BaseText tag={props.tag} className={`${styles.text} ${props.className}`} color={props.color}>
        {props.children}
    </BaseText>
);

TitleText.defaultProps = {
    className: '',
    color: 'dark',
    tag: 'p',
};

export default TitleText;
