import React from 'react';
import { useTranslation } from 'next-i18next';
import Message from 'src/shared/config/Message';
import localeUtils from 'src/shared/utils/localeUtils';

const useFields = () => {
    const { t, i18n } = useTranslation();
    return [
        {
            controlId: 'name',
            label: t('home_contact_sales_ask_name'),
            type: 'text',
            readOnly: false,
            validate: [
                {
                    required: true,
                    message: t(Message.FIELD_REQUIRED),
                },
            ],
        },
        {
            controlId: 'phoneNumber',
            label: t('home_contact_sales_phone'),
            type: {
                name: 'phoneNumber',
                locale: i18n.language,
            },
            value: {
                code: localeUtils.getDefaultPhoneCountryCode(),
            },
            readOnly: false,
            validate: {
                phoneNumberRequired: true,
                phoneCodeRequired: true,
                message: t(Message.FIELD_REQUIRED),
            },
            maxMenuHeight: 250,
        },
        {
            controlId: 'message',
            label: t('home_contact_sales_message'),
            type: 'text',
            validate: [
                {
                    required: true,
                    message: t(Message.FIELD_REQUIRED),
                },
            ],
        },
    ];
};

export default useFields;
