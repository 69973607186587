import React, { useState } from 'react';
import { Form } from 'react-apoidea';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';

import Message from 'src/shared/config/Message';
import GaAction from 'src/config/GaAction';
import GaCategory from 'src/config/GaCategory';
import GaService from 'src/services/GaService';
import globalActions from 'src/shared/store/global/actions';
import AccountAPI from 'src/shared/services/api/AccountAPI';
import TitleText from 'src/shared/components/TitleText';
import useFields from './hooks/useFields';

import styles from './styles.module.scss';
import RoundedButton from 'src/shared/components/RoundedButton';
import { fireGA } from 'src/utils/gaUtils';

interface Props {
    className?: string;
    titleClassName?: string;
    descriptionClassName?: string;
    submitButtonClassName?: string;
    title?: string;
    description?: string;
    onFireGa: () => void;
}

const FORM_THEME = {
    name: 'label',
    fullWidthLabelUnder: 'xxxl',
};

const ContactSalesForm = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fields = useFields();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async ({ formData }) => {
        if (isSubmitting) {
            return false;
        }

        props.onFireGa();

        const { phoneNumber } = formData;

        const submitData = phoneNumber
            ? {
                  ...formData,
                  phoneNumber: '+' + phoneNumber.code.toString() + phoneNumber.number,
              }
            : { ...formData };

        setIsSubmitting(true);
        const resp = await AccountAPI.contactSales(submitData);

        if (!resp.status) {
            dispatch(
                globalActions.showAlertDialog({
                    text: resp.message || t(Message.NETWORK_ERROR),
                    // TODO change to another dialog
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onConfirm: () => {},
                })
            );
            GaService.callEvent(GaCategory.marketing, GaAction.contactSalesFailed, resp.message);
            setIsSubmitting(false);
            return false;
        }
        dispatch(
            globalActions.showAlertDialog({
                text: t('result_submit_success'),
            })
        );
        GaService.callEvent(GaCategory.marketing, GaAction.contactSalesSuccess, resp.message);
        setIsSubmitting(false);

        return true;
    };

    const renderSubmitButton = (submitFunc) => (
        <RoundedButton
            className={`${styles.submitButton} ${props.submitButtonClassName}`}
            type="primary-on-light"
            loading={isSubmitting}
            onClick={submitFunc}
        >
            {t('home_contact_sales_button')}
        </RoundedButton>
    );

    return (
        <div className={`${styles.container} ${props.className}`}>
            <TitleText tag="h1" className={`${styles.title} ${props.titleClassName}`}>
                {props.title}
            </TitleText>
            {props.description && <div className={`${styles.description} ${props.descriptionClassName}`}>{props.description}</div>}
            <Form theme={FORM_THEME} className={styles.form} fields={fields} onSubmit={onSubmit} submitRenderer={renderSubmitButton} />
        </div>
    );
};

ContactSalesForm.defaultProps = {
    className: '',
    titleClassName: '',
    descriptionClassName: '',
    submitButtonClassName: '',
    title: '',
    description: '',
};

export default ContactSalesForm;
