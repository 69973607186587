import React from 'react';
import useDisclaimer from 'src/hooks/useDisclaimer';

const withDisclaimer = () => (WrappedComponent) => {
    const Component = (props) => {
        useDisclaimer();
        return <WrappedComponent {...props} />;
    };

    return Component;
};

export default withDisclaimer;
