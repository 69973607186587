import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';

class Section extends React.PureComponent {
    render() {
        const {
            className,
            containerClassName,
            children,
            fullWidth,
            withFixedHeader,
            containerStyle,
            sectionClassName,
            isArticle,
            dataTest,
            ...otherProps
        } = this.props;
        const contentClass = classnames(styles.content, className, {
            [styles.fullWidth]: fullWidth,
            [styles.withFixedHeader]: withFixedHeader,
        });

        return (
            <div className={`${styles.container} ${sectionClassName} ${containerClassName}`} style={containerStyle} {...otherProps}>
                {!isArticle && (
                    <div className={contentClass} data-test={dataTest}>
                        {children}
                    </div>
                )}
                {isArticle && (
                    <article className={contentClass} data-test={dataTest}>
                        {children}
                    </article>
                )}
            </div>
        );
    }
}

Section.defaultProps = {
    fullWidth: false,
    withFixedHeader: false,
    sectionClassName: null,
    className: null,
    containerClassName: '',
    containerStyle: {},
    isArticle: false,
    dataTest: '',
};

Section.propTypes = {
    fullWidth: PropTypes.bool,
    withFixedHeader: PropTypes.bool,
    sectionClassName: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    containerStyle: PropTypes.object,
    isArticle: PropTypes.bool,
    dataTest: PropTypes.string,
};

export default Section;
