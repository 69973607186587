import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import common from 'src/shared/utils/common';
import CATEGORY from 'src/config/MediaCategory';

// TODO move to datetime config

const useArticleInfo = (article) => {
    const { t, i18n } = useTranslation();
    const { category, author, publishedAt, startDate } = article;

    // FIXME: Workaround for fixing datetime display. Should be fixed by dividing into datetime files for defining diff datetime format.
    const isZh = i18n.language === 'tc' || i18n.language === 'sc';
    const DATE_FORMAT = isZh ? 'YYYY[年]MMMD[日]' : 'DD MMM YYYY';
    const TIME_FORMAT = isZh ? 'Ahh:mm' : 'hh:mmA';

    const startDateText = startDate ? common.formatDateToString(startDate, DATE_FORMAT) : '';

    const startTimeText = startDate ? common.formatDateToString(startDate, TIME_FORMAT) : '';

    const publishedAtText = common.formatDateToString(publishedAt, DATE_FORMAT);

    const infoText = useMemo(() => {
        let tokens;
        switch (category && category.categoryUrl) {
            case CATEGORY.INSIGHTS:
            case CATEGORY.NEWS:
                tokens = [`${t('authorized_by')} ${author}`, publishedAtText];
                break;
            case CATEGORY.EVENTS:
                tokens = startDate ? [startDateText, startTimeText] : [];
                break;
            default:
                tokens = [];
        }
        return tokens.join('．');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article]);

    const categoryLabel = category ? t(`article_category_${category.categoryName.toLowerCase()}`) : '';

    return {
        startDateText,
        startTimeText,
        publishedAtText,
        infoText,
        categoryLabel,
    };
};

export default useArticleInfo;
