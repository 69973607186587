import ArticlePostModel from 'src/types/article/ArticlePostModel';
import RequestService from 'src/shared/services/RequestService';
import Language from 'src/config/Language';

// eslint-disable-next-line prefer-destructuring
const BASE_MEDIA_API_URL = process.env.BASE_MEDIA_API_URL;
// eslint-disable-next-line prefer-destructuring
const MEDIA_PRODUCT_ID = process.env.MEDIA_PRODUCT_ID;

const headers = { 'product-id': MEDIA_PRODUCT_ID };

type GetArticleListResult = { status: number; data: { total: number; hits: Array<ArticlePostModel> } };

const getArticleList = ({
    page = 1,
    category = null,
    tag = null,
    limit = 20,
    featured = false,
    language = '',
}: {
    page?: number;
    category?: string | string[] | null;
    tag?: string | null;
    limit?: number;
    featured?: boolean;
    language?: string;
}): Promise<GetArticleListResult> => {
    const offset = page > 1 ? (page - 1) * limit : 0;
    let url = `${BASE_MEDIA_API_URL}/article/v2/altive-post?offset=${offset}&limit=${limit}`;
    if (category) {
        if (Array.isArray(category)) {
            url += `&category=${encodeURIComponent(category.join(','))}`;
        } else {
            url += `&category=${encodeURIComponent(category)}`;
        }
    }
    if (tag) {
        url += `&tag=${encodeURIComponent(tag)}`;
    }
    if (featured) {
        url += `&featured=true`;
    }
    if (language) {
        url += `&language=${language}`;
    }
    return RequestService.get(url, { headers })
        .then((res) => ({ status: 1, data: res.data }))
        .catch((err) => ({ status: 0, code: err.response, message: err.message, data: null }));
};

const getArticleGroup = ({ groupId = '' }) => {
    const url = `${BASE_MEDIA_API_URL}/article/v2/altive-post?groupId=${groupId}`;
    return RequestService.get(url, { headers })
        .then((res: any) => {
            const langs = Object.entries(Language)
                .filter(([_, value]) => !value.hide)
                .map(([key, _]) => key);
            if (!res) {
                return langs.reduce((acc, lang) => ({ ...acc, [lang]: null }), {});
            }
            return langs.reduce((acc, lang) => ({ ...acc, [lang]: res.data.hits.find((x) => x.language === Language[lang].mediaLocale) }), {});
        })
        .catch((err) => ({ status: 0, code: err.response, message: err.message, data: null }));
};
getArticleGroup.key = `${BASE_MEDIA_API_URL}/article/v2/altive-post?groupId=groupId`;

type GetArticleDetailByIdOrUrl = { status: number; data: ArticlePostModel };

const getArticleDetailByIdOrUrl = (idOrUrl: string): Promise<GetArticleDetailByIdOrUrl> => {
    const url = `${BASE_MEDIA_API_URL}/article/v2/altive-post/${idOrUrl}`;

    return RequestService.get(url, { headers })
        .then((res) => ({ status: 1, data: res.data }))
        .catch((error) => ({ status: 0, code: error.response, data: null }));
};

const ArticleAPI = {
    getArticleList,
    getArticleDetailByIdOrUrl,
    getArticleGroup,
};

export default ArticleAPI;
