const NETWORK_ERROR = 'NETWORK_ERROR'; // 'Network Error. Please try again.';
const ENGLISH_ONLY = 'ENGLISH_ONLY'; // 'Please Input in english.';

// message from forms
const ONE_FIELD_ONLY_REQUIRED = 'ONE_FIELD_ONLY_REQUIRED'; // 'one field ONLY is reqiured.';
const CANT_TICK_MORE_THAN_ONE_BOX = 'CANT_TICK_MORE_THAN_ONE_BOX'; // 'cannot tick more than one box';
const AT_LEAST_ONE_FIELD_REQUIRED = 'At least one field is required'; // At least one field is required
const UPPERLOWER_INVALID = 'Upper value should be higher or equal to lower value';
const AMOUNT_INVALID = 'Please input correct amount(not less than 0)';
const MINIMAL_AMOUNT_INVALID = 'Please input correct amount (a multiple of 1000 and not less than 1000)';
const PERCENTAGE_INVALID = 'Please input correct percentage (0-100)';
const FIELD_REQUIRED = 'FIELD_REQUIRED'; // 'This field is required.';
const EMAIL_REQUIRED = 'EMAIL_REQUIRED'; // 'Please input valid email.';
const VALUE_REQUIRED = 'VALUE_REQUIRED'; // 'Required.';
const DATE_AND_TIME_REQUIRED = 'Please select both date and time';
const PASSCODE_INVALID = 'PASSCODE_INVALID'; // 'Passcode is incorrect';
const PASSWORD_DIFFERENT = 'PASSWORD_DIFFERENT'; // 'Password is not the same.';
const VALUE_NOT_INT = 'VALUE_NOT_INT'; // 'Value must be integer';
const VALUE_LESS_MIN = 'VALUE_LESS_MIN'; // 'Value must be greater than minimum investment';
const INV_AMT_NOT_THOUSAND = 'INV_AMT_NOT_THOUSAND'; // 'Investment amount can only be in thousand';
const VALUE_NOT_IN_RANGE = 'VALUE_NOT_IN_RANGE'; // 'Value must be within the range';
const DUPLICATED_INV_ENT = 'DUPLICATED_INV_ENT'; // 'Duplicate Investing Entity';
const GENERAL_ERROR = 'GENERAL_ERROR'; // 'Error Occurs. Please try again later.';
const PASSWORD_REQUIRED = 'PASSWORD_REQUIRED'; // 'Please input your password.';
const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'; // 'Please input your new password.';
const PASSWORD_8_CHARACTERS = 'PASSWORD_8_CHARACTERS'; // 'Password at least 8 string.';
const DATE_ERROR = 'DATE_ERROR'; // Date must be in format YYYY-MM-DD';
const DAY_INVALID = 'Please input correct number (1-31)';
const PERIOD_ERROR = 'Period must be in format YYYY-MM'; // Period must be in format YYYY-MM';
const ID_NUMBER_ERROR = 'ID_NUMBER_ERROR'; // Please input ID without any symbol, e.g. input A123456(7) as A1234567
const PASSWORD_POLICY_MISMATCH = 'PASSWORD_POLICY_MISMATCH'; // Your password must be using 8 or more characters with a mix of uppercase letters, lowercase letters, numbers and symbols
const DOCUSIGN_ID_FORMAT_INCORRECT = 'Please input correct DocuSign ID, e.g. 00000000-0000-0000-0000-000000000000';
const CAPITAL_CALL_PERCENTAGE_INCORRECT = 'exceeded the total committed percentage';
const CAPITAL_CALL_AMOUNT_INCORRECT = 'exceeded the total committed amount';

export default {
    NETWORK_ERROR,
    ENGLISH_ONLY,
    ONE_FIELD_ONLY_REQUIRED,
    CANT_TICK_MORE_THAN_ONE_BOX,
    FIELD_REQUIRED,
    AT_LEAST_ONE_FIELD_REQUIRED,
    EMAIL_REQUIRED,
    VALUE_REQUIRED,
    DATE_AND_TIME_REQUIRED,
    PASSCODE_INVALID,
    PASSWORD_DIFFERENT,
    VALUE_NOT_INT,
    VALUE_LESS_MIN,
    INV_AMT_NOT_THOUSAND,
    VALUE_NOT_IN_RANGE,
    DUPLICATED_INV_ENT,
    GENERAL_ERROR,
    PASSWORD_REQUIRED,
    NEW_PASSWORD_REQUIRED,
    PASSWORD_8_CHARACTERS,
    DATE_ERROR,
    DAY_INVALID,
    PERIOD_ERROR,
    ID_NUMBER_ERROR,
    PASSWORD_POLICY_MISMATCH,
    PERCENTAGE_INVALID,
    UPPERLOWER_INVALID,
    AMOUNT_INVALID,
    MINIMAL_AMOUNT_INVALID,
    DOCUSIGN_ID_FORMAT_INCORRECT,
    CAPITAL_CALL_PERCENTAGE_INCORRECT,
    CAPITAL_CALL_AMOUNT_INCORRECT,
};
